<template>
  <el-container>
    <el-main>
      <div class="blodTitle">抖音小程序</div>
      <img src="@/assets/image/dybanner.png" alt="" />
      <div class="blodTitle">授权及发布抖音小程序</div>
      <Empower></Empower>
      <div class="blodTitle">服务优势</div>
      <div class="operateArr">
        <div v-for="(item, index) in ServiceAdvantage" :key="index">
          <img :src="item.img" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="bot">{{ item.content }}</div>
        </div>
      </div>
      <div class="blodTitle">特色功能</div>
      <div class="spFunction">
        <div v-for="(item, index) in spFunction" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
      <div class="blodTitle">优秀案例</div>
      <div class="anliList">
        <div v-for="(item, index) in moreArr" :key="index">
          <img class="qr" :src="item.qrcode" alt="" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import Empower from './sonPage/douyin/empower.vue'

export default {
  components: {
    Empower
  },
  data() {
    return {
      ServiceAdvantage: [
        {
          img: require('@/assets/image/dys01.png'),
          title: '庞大的用户群体',
          content: '四亿活跃用户，覆盖不同圈层'
        },
        {
          img: require('@/assets/image/dys02.png'),
          title: '多行业合作生态',
          content: '覆盖多行业，诚邀各类优势行业合作伙伴共建良性生态'
        },
        {
          img: require('@/assets/image/dys03.png'),
          title: '多端服务支持',
          content: '支持移动端/PC/小程序的服务搭建，实现业务流量和转化升级'
        },
        {
          img: require('@/assets/image/dys04.png'),
          title: '丰富的解决方案',
          content: '为广大内容创作者和服务商提供行业解决方案'
        },
        {
          img: require('@/assets/image/dys05.png'),
          title: '完善的服务支持',
          content: '完善的对接服务，提供沙盒测试环境和应用监控'
        }
      ],
      moreArr: [
        { name: '雅兰天丽美妆', qrcode: require('@/assets/image/qrcode.png') },
        { name: '天宜世家家居', qrcode: require('@/assets/image/qrcode.png') },
        { name: '伊乐贝斯女装', qrcode: require('@/assets/image/qrcode.png') },
        { name: '日易惠百货用品', qrcode: require('@/assets/image/qrcode.png') },
        { name: '新稻香农产品', qrcode: require('@/assets/image/qrcode.png') }
      ],
      spFunction: [
        { title: '全内容挂载', content: '文章、视频、直播等多种体裁可按内容需求和相关性挂载小程序，实现内容到服务的用户升级需求满足。' },
        { title: '信息流分发', content: '信息流分发给小程序带来无限扩充的精准流量，拥有优质内容的小程序即可获得超千万次曝光机会。' },
        { title: '变现能力', content: '为开发者提供多种商业化变现方式，实现将流量获取直接转化为收入变现，致力于不断提高流量变现效率。' },
        { title: '搜索直达', content: '基于搜索场景实现精准拉新，搜索词推荐直达、字节如意等多种展现形式，强化小程序形态，直观展示小程序的服务。' }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.blodTitle {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin: 40px 0 20px 0;
  .subTitle {
    color: #5654f3;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    margin-left: 23px;
  }
}
.el-container {
  width: 100%;
  background: #fff;
}
.el-main {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 20px;
  & > img {
    width: 100%;
  }
  .isAuthorization {
    width: 100%;
    display: flex;
    & > div {
      width: 380px;
      padding: 20px;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #f7f8fa;
      margin-right: 20px;
    }
    .top {
      font-size: 18px;
      color: #333;
      text-align: center;
      font-weight: bold;
    }
    .mid {
      width: 100%;
      font-size: 14px;
    }
    .bot {
      width: 228px;
      height: 32px;
      background: #1676fe;
      border-radius: 2px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      align-self: center;
    }
  }

  .operateArr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 14px;
    & > div {
      width: 200px;
      text-align: center;
    }
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      margin: 20px 0;
    }
    .bot {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
  }
  .spFunction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    & > div {
      width: 390px;
      height: 150px;
      background: #fbfbfb;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 25px 55px;
      margin-bottom: 20px;
    }
    .title {
      font-size: 16px;
      margin-bottom: 20px;
    }
    .content {
      color: #666666;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .anliList {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    flex-wrap: wrap;
    img {
      width: 110px;
      height: 110px;
    }
    & > div {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-bottom: 20px;
    }
    & > div:not(:last-child) {
      margin-right: 180px;
    }
  }
}
</style>
