<template>
  <div>
    <div class="steps" v-for="(item, index) in authorizationArr" :key="index">
      <div class="top">
        <div class="left" v-if="authorizationSteps <= index" :style="{ background: authorizationSteps === index ? '#170B1A' : '#D8D8D8' }">
          {{ index + 1 }}
        </div>
        <div class="left1" v-else>
          <i class="el-icon-check"></i>
        </div>
        <div class="mid">
          {{ item.stageTitle }}
          <div v-if="index == 2" class="copyParams" @click="copyId($event)">复制打包参数</div>
        </div>
        <div class="right">
          <div
            v-if="authorizationSteps <= index"
            class="canBtn"
            :style="{
              color: authorizationSteps === index ? '#fff' : '#999999',
              background: authorizationSteps === index ? '#170B1A' : '#EBEBEB',
              cursor: index == 2 ? 'auto' : '',
            }"
            @click="btnFunction(index)"
          >
            {{ item.btnTxt }}
          </div>
          <div v-else class="rightElse">
            <div v-if="index === 0">
              <i class="el-icon-check"></i>
              已配置
            </div>
            <div v-if="index === 1">
              <i class="el-icon-check"></i>
              已配置
            </div>
            <div v-if="index === 2">
              <i class="el-icon-check"></i>
              已发布
            </div>
          </div>
        </div>
      </div>
      <div class="bot">
        <div class="left" v-if="index !== authorizationArr.length - 1">
          <div class="xian" :style="{ background: authorizationSteps >= index + 1 ? '#170B1A' : '#eeeeee' }"></div>
        </div>
        <div class="mid">
          <div v-if="index === 0">
            <p v-if="dataInfo.name">抖音小程序名称：{{ dataInfo.name }}</p>
          </div>
          <div v-if="index === 1"></div>
          <div v-if="index === 2"></div>
        </div>
        <div class="right">
          <div v-if="index === 0 && authorizationSteps > 0">
            <span @click="lookOne(index)">查看</span>
          </div>
          <div v-if="index === 1 && authorizationSteps > 1">
            <span @click="lookOne(index)">查看</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="基础设置" :visible.sync="basicFlag" width="52%">
      <Basic ref="Basic" @updateBasicsSu="updateBasicsSu" :dataInfo="dataInfo" :authorizationSteps="authorizationSteps"></Basic>
      <!--  v-if="!isCanUpdate" -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="basicFlag = false">取 消</el-button>
        <el-button type="primary" @click="updateSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="支付设置" :visible.sync="PayConfigFlag" width="52%">
      <PayConfig ref="PayConfig" @updatePaySu="updatePaySu" :dataInfo="dataInfo" :authorizationSteps="authorizationSteps"></PayConfig>
      <!-- v-if="!isCanUpdate" -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="PayConfigFlag = false">取 消</el-button>
        <el-button type="primary" @click="updatePayConfigSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Basic from '../douyin/basic.vue';
import PayConfig from '../douyin/payConfig.vue';
import clipboard from '@/util/clipBoard.js';
export default {
  components: {
    Basic,
    PayConfig,
  },
  data() {
    return {
      // 支付设置弹框
      PayConfigFlag: false,
      // 基础设置弹框
      basicFlag: false,
      // 步骤状态
      authorizationSteps: 0,
      // 审核步骤合集
      authorizationArr: [
        { stageTitle: '已注册抖音小程序，立即配置基础设置', btnTxt: '立即配置' }, // 表单输入 小程序名字 appid 秘钥
        { stageTitle: '基础设置成功，立刻支付配置', btnTxt: '立即配置' },
        { stageTitle: '配置成功，请下载代码包，并在抖音开放平台上传小程序', btnTxt: '等待上传' },
      ],
      dataInfo: {},
      isCanUpdate: 0,
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    // 获取抖音信息
    getInfo() {
      let that = this;
      this.$axios.post(this.$api.set.DouyinInfo).then(res => {
        if (!res.result) {
          that.authorizationSteps = 0;
        } else {
          that.dataInfo = res.result;
          that.authorizationSteps = res.result.appstatus;
          if (res.result.appstatus == 3) {
            this.authorizationArr[2].btnTxt = '等待审核';
          }
        }
      });
    },
    // 点击配置按钮
    btnFunction(index) {
      if (index == 0) {
        if (this.authorizationSteps == 0) {
          this.basicFlag = true;
        }
      } else if (index == 1) {
        if (this.authorizationSteps == 1) {
          this.PayConfigFlag = true;
          this.$nextTick(() => {
            this.$refs.PayConfig.getInfo();
          });
        }
      }
    },
    BasicClose() {
      this.isCanUpdate = 0;
    },
    // 确认修改基础配置
    updateSure() {
      this.$nextTick(() => {
        this.$refs.Basic.preservation();
      });
    },
    lookOne(index) {
      if (index == 0) {
        this.isCanUpdate = 1;
        this.basicFlag = true;
        this.$nextTick(() => {
          this.$refs.Basic.name = this.dataInfo.name;
        });
      } else if (index == 1) {
        this.PayConfigFlag = true;
        this.$nextTick(() => {
          this.$refs.PayConfig.getInfo();
        });
      }
    },
    // 填写基础配置成功
    updateBasicsSu(name) {
      if (!this.authorizationSteps) {
        this.authorizationSteps = 1;
      }
      this.basicFlag = false;
      this.dataInfo.name = name;
    },
    // 点击保存支付配置
    updatePayConfigSure() {
      this.$nextTick(() => {
        this.$refs.PayConfig.preservation();
      });
    },
    // 保存支付配置成功
    updatePaySu() {
      if (this.authorizationSteps == 1) {
        this.authorizationSteps = 2;
      }
      this.PayConfigFlag = false;
    },
    // 立即发布
    release() {},
    copyId(e) {
      let id = String(localStorage.getItem('u_id'));
      console.log(e, id);
      clipboard(e, id);
    },
  },
  watch: {
    PayConfigFlag(val) {
      if (!val) {
        setTimeout(() => {
          this.isCanUpdate = 0;
        }, 200);
      }
    },
    basicFlag(val) {
      if (!val) {
        setTimeout(() => {
          this.isCanUpdate = 0;
        }, 200);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cupo {
  cursor: pointer;
}
.fc666 {
  color: #666 !important;
}
.fc409 {
  color: #409eff;
}
.fc999 {
  color: #999 !important;
}
.fc852 {
  color: #170b1a !important;
  cursor: pointer;
}
.canBtn {
  width: 96px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
}
.steps {
  width: 100%;
  & > div {
    display: flex;
    justify-content: space-between;
    .left {
      flex: 0 0 24px;
    }
    .mid {
      flex: 1;
      padding: 0 10px;
    }
    .right {
      flex: 0 0 96px;
    }
  }
  .top {
    align-items: center;
    .left {
      height: 24px;
      text-align: center;
      line-height: 24px;
      color: #fff;
      border-radius: 50%;
    }
    .left1 {
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 22px;
      border: 2px solid #170b1a;
      border-radius: 50%;
      i {
        color: #170b1a;
        font-weight: bold;
      }
    }
    .mid {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;
    }
    .rightElse {
      flex-wrap: nowrap;
      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        i {
          width: 16px;
          height: 16px;
          background: #170b1a;
          border-radius: 50%;
          color: #fff;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          font-weight: bold;
          margin-right: 8px;
        }
      }
    }
  }
  .bot {
    min-height: 100px;
    .left {
      .xian {
        width: 2px;
        min-height: 80px;
        margin: 10px auto;
      }
    }
    .mid {
      & > div {
        p {
          font-size: 14px;
          color: #666;
          margin-top: 5px;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      div {
        text-align: right;
      }
      span {
        cursor: pointer;
        user-select: none;
        align-self: flex-end;
        font-size: 14px;
        color: #170b1a;
      }
    }
  }
  .bot:last-child {
    min-height: 50px;
  }
}
.el-table {
  margin-bottom: 10px;
  /deep/ .el-table__body-wrapper {
    height: 40vh;
    overflow: hidden;
    overflow-y: auto;
  }
}
.descBox {
  display: flex;
  .title {
    width: 120px;
    text-align: right;
    flex-shrink: 0;
  }
  .el-textarea {
    /deep/ .el-textarea__inner {
      resize: none;
    }
  }
  .tips {
    width: calc(100% - 120px);
    color: red;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
